import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Capacitor} from "@capacitor/core";
import {AuthenticatorService} from "@aws-amplify/ui-angular";
import {URLService} from "../../../services";
import {InviteDTO, User} from '../../../global/interfaces';
import {CognitoService, ContactService, LoggerService, UserService} from '../../../global/services';
import {fetchAuthSession, getCurrentUser} from "aws-amplify/auth";
import {Hub} from 'aws-amplify/utils';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {

  logorsign: any;
  user: User;
  private readonly urlSub: Subscription;
  private authSub: Subscription;
  invite: InviteDTO;
  private hubListener;

  logger = this.logService.taggedLogger(this.constructor?.name);

  public formFields = {
    signUp: {
      name: {
        placeholder: "Full Name",
        required: true,
        order: 2,
      },
      email: {
        order: 1,
        placeholder: "Email",
        required: true,
      },
      password: {
        label: "Password",
        required: true,
        order: 3
      },
      confirm_password: {
        label: "Confirm Password",
        required: true,
        order: 4
      },
    },
  };
  private lastAuthStatus: "authenticated";

  constructor(
    private redirect: URLService,
    public authenticated: AuthenticatorService,
    private logService: LoggerService,
    private router: Router,
    private route: ActivatedRoute,
    private contactService: ContactService
  ) {

    const url = this.router.url.split("?")[0];

    this.logorsign = (url) === "/login" ? "" : "signUp";

    this.route.paramMap.subscribe(params => {
      const inviteId = params.get('inviteid');
      console.log("**** INVITE ID: ", inviteId);

      // get contact details from invite
      if (!!inviteId) {
        this.contactService.getInvite(inviteId).then(res => {
          this.invite = res;

          this.setDefault("email", res.email);
          this.setDefault("name", res.contact.contactName);
        });
      }
    });


    if (!this.urlSub) {

      this.urlSub = this.router.events.subscribe(async (event) => {

        if (event instanceof NavigationEnd && event.url.startsWith("/login")) {

          fetchAuthSession({forceRefresh: true}).then((y) => {

            this.logger.debug("LOGIN: AUTH SESSION - ", y);

            getCurrentUser().then((x) => {

              this.logger.debug("LOGIN: CURRENT USER - ", x);

              // if (!Capacitor.isNativePlatform()) {
              console.log("GOING TO USER CHECKS FROM CONSTRUCTOR");
              this.redirect.userChecks();
              // }

            }).catch((x) => {
              this.logger.debug("LOGIN: USER NOT LOGGED IN - ", x);
            });
          }).catch((x) => {
            this.logger.debug("CANNOT FETCH SESSION - ", x);
          });
        }
      });
    }
  }

  ngOnInit() {

    this.hubListener = Hub.listen('auth', (data) => {
      const {payload} = data;

      console.log('A Hub message was received:', data);
      if (payload.event === 'signInWithRedirect_failure') {
        console.log('Sign-in failure detected:', payload);
        const email = this.getInput('username');
        this.contactService.loginFailed(email);
      }
    });

    // If we aren't logged in already, subscribe to the auth status
    if (this.authenticated.authStatus != 'authenticated') {

      this.authenticated.subscribe(message => {

        if (this.authenticated.authStatus === "authenticated" && this.authenticated.authStatus != this.lastAuthStatus) {

          this.lastAuthStatus = this.authenticated.authStatus;

          fetchAuthSession({forceRefresh: true}).then((y) => {
            this.logger.info("USER IS NOW AUTHENTICATED");
            console.log("GOING TO USER CHECKS FROM ON INIT");
            this.redirect.userChecks();
          });
        }
      });
    }
  }

  async ngAfterViewInit() {

  }

  getInput(inputName) {
    const inputs = document.querySelectorAll('input[name="' + inputName + '"]');
    return (inputs[0] as HTMLInputElement).value;
  }

  setDefault(inputName, inputValue) {

    const inputs = document.querySelectorAll('input[name="' + inputName + '"]');

    // Iterate nodelist of elements and set the value of each to "abc"
    inputs.forEach((input) => {
      (input as HTMLInputElement).value = inputValue;
    });
  }

  async OnDestroy() {
    this.urlSub.unsubscribe();
    this.authSub.unsubscribe();
  }
}

