export interface AdminStaffCallRatioTotalsDTO {
  /**
   * The name of the staff member (Contact).
   */
  contactName: string;

  /**
   * Total number of customers assigned to the staff member in the last 6 months.
   */
  totalCustomerCount: number;

  /**
   * Number of customers assigned to the staff member in the last 30 days.
   */
  last30DaysCustomerCount: number;

  /**
   * Total number of outbound calls made by the staff member in the last 6 months.
   */
  totalCalls: number;

  /**
   * Ratio of total calls to total customers in the last 6 months (percentage).
   */
  totalCallToCustomerRatio: number;

  /**
   * Number of outbound calls made by the staff member in the last 30 days.
   */
  last30DaysCalls: number;

  /**
   * Ratio of calls to customers in the last 30 days (percentage).
   */
  last30DaysCallToCustomerRatio: number;

  /**
   * Delta between the total customers and the target customers over 6 months (150 per month).
   */
  totalCustomerDelta: number;

  /**
   * Delta between the customers in the last 30 days and the target customers (150 for 1 month).
   */
  last30DaysCustomerDelta: number;
}
