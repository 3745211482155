import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AdminUrlService, AdminWhosWhoService} from './services';
import {Subscription} from 'rxjs';
import {EventService, RoleGuardService, SignalRService, URLService} from '../../services';
import {NavigationEnd, Router} from "@angular/router";
import {UserService} from '../../global/services';
import {User} from '../../global/interfaces';
import {MessageTypeEnum, UserRolesEnum} from '../../global/enums';

@Component({
  selector: 'app-main',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss', '../../../../../common/components/app-side-menu/app-side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminComponent implements OnInit, AfterViewInit {

  private connectedSub: Subscription;
  private user: User;
  public hideSearchBar: boolean;
  public hideNavBar: boolean;
  defaultMenuItems = [];

  @ViewChild('sidenav', {static: true}) public el: any;

  public menuItems = [];
  customRoutes: any;
  public showSideNav: any;
  private adminEventSub: Subscription;
  pendingTotal = null

  @HostListener('swiperight', ['$event'])
  public swipePrev(event: any) {
    this.el.show();
  }

  constructor(
    private eventService: EventService,
    private signalRService: SignalRService,
    private authService: RoleGuardService,
    private userService: UserService,
    public url: URLService,
    public adminUrl: AdminUrlService,
    public router: Router,
    private host: ElementRef<HTMLElement>,
    private whoswhoService: AdminWhosWhoService,
  ) {
    this.signalRService.startConnection();

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        this.createNavs(event.url);
      }
    });

    this.setDefaultMenu();
  }

  private setDefaultMenu() {
    this.defaultMenuItems = [

      {itemRoute: this.url.dashboard(true), itemLabel: "Home"},
      {itemRoute: this.url.admin(true), itemLabel: "Dashboard"},
      {itemRoute: this.adminUrl.adminTasks(true), itemLabel: "Tasks", roles: [UserRolesEnum.Admin]},
      {itemRoute: this.adminUrl.adminBrokerage(true), itemLabel: "Brokerage", roles: [UserRolesEnum.Admin]},
      {itemRoute: this.adminUrl.adminWhosWho(1, 1, true), itemLabel: "Who's Who", roles: [UserRolesEnum.Admin]},
      {itemRoute: this.adminUrl.adminCallRecords(true), itemLabel: "Call Records", roles: [UserRolesEnum.Admin]},
      {itemRoute: this.adminUrl.adminDeals(0, true), itemLabel: "Deals Agreed", roles: [UserRolesEnum.Admin]},
      {
        itemRoute: "", itemLabel: "Sales", roles: [UserRolesEnum.SuperAdmin],
        subMenu: [
          {itemRoute: this.adminUrl.adminSales(true), itemLabel: "Sales"},
          {itemRoute: this.adminUrl.adminAuctions(true), itemLabel: 'Post Sale', roles: [UserRolesEnum.AuctionAdmin]},
          {
            itemRoute: this.adminUrl.adminSaleAttendees(true),
            itemLabel: 'Attendees',
            roles: [UserRolesEnum.AuctionAdmin]
          },
        ],
      },
      {
        itemRoute: "", itemLabel: "Import", roles: [UserRolesEnum.Admin],
        subMenu: [
          {itemRoute: this.adminUrl.importData( "europcar", true), itemLabel: "Europcar"},
        ],
      },
      {
        itemRoute: "", itemLabel: "Vehicles",
        subMenu: [
          {itemRoute: this.adminUrl.adminVehiclesHistory(true), itemLabel: 'Vehicle Audit'},
          {itemRoute: this.adminUrl.adminVehiclesChecker(true), itemLabel: 'Vehicle Checker'},
        ],
      },
      {
        itemRoute: "", itemLabel: "Valuations", roles: [UserRolesEnum.SuperAdmin],
        subMenu: [
          {itemRoute: this.adminUrl.adminValuations(true), itemLabel: 'Profiles'},
          {itemRoute: "/admin/value-dev", itemLabel: 'Test', roles: [UserRolesEnum.Admin]},
          {itemRoute: "/admin/value-history", itemLabel: 'History', roles: [UserRolesEnum.Admin]},
        ],
      },
      {
        itemRoute: "", itemLabel: "Accounting", roles: [UserRolesEnum.Admin],
        subMenu: [
          {itemRoute: this.adminUrl.adminInvoices(true), itemLabel: "Invoices"},
        ]
      },
      {
        itemRoute: "", itemLabel: "Scanning", roles: ["GOD"],
        subMenu: [
          {itemLabel: "Dashboard", itemRoute: this.adminUrl.adminScanDashboard(true)},
          {itemLabel: "Errors", itemRoute: this.adminUrl.adminScanErrorSummary(true)},
          {itemLabel: "Configure", itemRoute: this.adminUrl.adminScanConfigure(true)},
          {itemLabel: "ScanFields", itemRoute: this.adminUrl.adminScanField(true)},
          {itemLabel: "Services", itemRoute: this.adminUrl.adminScanService(true)},
          {itemLabel: "Styles", itemRoute: this.adminUrl.adminScanStyle(true)},
          {itemLabel: "ScanCustomers", itemRoute: this.adminUrl.adminScanCustomer(true)},
          {itemLabel: "ScanVehicles", itemRoute: this.adminUrl.adminScanVehicles(true)},
          {itemLabel: "Queue", itemRoute: this.adminUrl.adminScanQueue(true)},
        ]
      },
      {
        itemRoute: "", itemLabel: "Admin", roles: ["GOD"],
        subMenu: [
          {itemRoute: this.adminUrl.adminCustomers('', true), itemLabel: "Customers"},
          {itemRoute: this.adminUrl.commsTemplates(1, true), itemLabel: "Communications"},
          {itemRoute: this.adminUrl.siteAdmins(true), itemLabel: "Site Admins"}
        ]
      },
      {
        itemRoute: "", itemLabel: "Settings", roles: ["GOD"],
        subMenu: [
          {itemRoute: this.adminUrl.adminSaleProfiles(true), itemLabel: "Sale Profiles"},
          {itemLabel: "Attribs", itemRoute: this.adminUrl.adminMaintAttrib(true)},
          {itemLabel: "MakeModels", itemRoute: this.adminUrl.adminAlts(true)},
          {itemLabel: "Sites", itemRoute: this.adminUrl.adminMaintSite(true)},
          {itemLabel: "Country Products", itemRoute: this.adminUrl.adminMaintCountryProduct(true)},
          {itemLabel: "Platforms", itemRoute: this.adminUrl.adminMaintPlatform(true)},
          {itemLabel: "Tax Rates", itemRoute: this.adminUrl.adminMaintTax(true)},
          {itemLabel: "Products", itemRoute: this.adminUrl.adminMaintProduct(true)},
          {itemLabel: "Countries", itemRoute: this.adminUrl.adminMaintCountry(true)},
          {itemLabel: "Fault Checks", itemRoute: this.adminUrl.adminMaintFaultCheck(true)},
        ]
      },
    ];
  }

  async ngOnInit() {

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    this.createNavs(this.router.url);

    this.connectedSub = this.eventService.SignalRConnected.subscribe(() => {
      this.signalRService.subscribeContactGroup(this.user.contactId).then(() => {
      });
    });

    this.adminEventSub = this.eventService.AuctioneerEvent.subscribe(event => {
      if (event.messageType == MessageTypeEnum.IdPendingDecrement) {
        this.setPendingBadge(this.pendingTotal - 1);
      }
    });

  }

  async ngAfterViewInit() {

    this.getWhosWhoBadge();

  }

  private getWhosWhoBadge() {

    this.whoswhoService.getPendingCount().then((data) => {


      this.setPendingBadge(data["pending"]);

    });
  }

  setPendingBadge(pendingTotal: number) {

    this.pendingTotal = pendingTotal;

    const menuItem = this.menuItems.find(x => x.itemLabel === "Who's Who");
    if (menuItem) {
      menuItem.badge = pendingTotal;
      menuItem.badgeClass = "badge-danger";
    }
  }

  private createNavs(url: string) {

    this.menuItems = this.defaultMenuItems;

    // filter out menu items based on user roles
    const items = [];
    this.menuItems.flatMap(x => ({menu: x, roles: x.roles})).forEach(x => {
      if (!x.roles || this.authService.HasRole(this.user, x.roles)) {
        items.push(x.menu);
      }
    });

    this.menuItems = items;
  }
}
