import {Injectable} from '@angular/core';
import {DataService} from "./data.service";
import {ApiService} from '../global/services/index';
import {
  AdviewDTO,
  AdViewSummaryDTO,
  SearchResultDTO,
  TypedSearchResultDTO,
  WhosWhoAdminSearchDTO
} from "../global/interfaces";
import {} from "../global/services";
import {} from "../global/enums";

@Injectable()
export class AdviewService {

  private serviceUrl = '/api/recordview';


  constructor(private apiClient: ApiService, private data: DataService) {
  }

  async recordView(advertId) {

    const url = `${this.data.apiUrl}${this.serviceUrl}/` + advertId;

    return await this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<any>;
  }

  getAdminAdviews(searchDTO: WhosWhoAdminSearchDTO): Promise<TypedSearchResultDTO<AdviewDTO>> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/search`;
    url += '?query=' + encodeURIComponent(JSON.stringify(searchDTO));
    return this.apiClient.get({url}) as Promise<TypedSearchResultDTO<AdviewDTO>>;
  }

  getAdvertViewSummary(advertId: string): Promise<AdViewSummaryDTO> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/${advertId}/summary`;
    return this.apiClient.get({url}) as Promise<AdViewSummaryDTO>;
  }

  getTopViewers(searchDTO: WhosWhoAdminSearchDTO): Promise<SearchResultDTO> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/top-viewers`;
    url += '?query=' + encodeURIComponent(JSON.stringify(searchDTO));
    return this.apiClient.get({url}) as Promise<SearchResultDTO>;
  }
}
