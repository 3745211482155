import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {URLService} from "../../../services/index";

@Injectable({providedIn: 'root'})
export class AdminUrlService {

  constructor(
    private router: Router,
    public urlService: URLService
  ) {
  }

  adminCustomers(statusId, returnOnly: boolean = false) {
    return this.commonReturn("/admin/customers/" + statusId, returnOnly);
  }

  adminWhosWho(tab: number = 1, page: number = 1, returnOnly: boolean = false, queryString: string = "") {

    let path = "/admin/whos-who/" + tab + "/" + page;
    if (queryString.length > 0) {
      path += "?query=" + queryString;
    }

    return this.commonReturn(path, returnOnly);
  }

  adminCallRecords(returnOnly: boolean = false) {
    return this.commonReturn("/admin/call-records", returnOnly);
  }

  adminBrokerage(returnOnly: boolean = false) {
    return this.commonReturn("/admin/brokerage", returnOnly);
  }

  adminTasks(returnOnly: boolean = false) {
    return this.commonReturn("/admin/admin-tasks", returnOnly);
  }

  adminOffers(returnOnly: boolean = false) {
    return this.commonReturn("/admin/offers", returnOnly);
  }

  adminAdverts(returnOnly: boolean = false) {
    return this.commonReturn("/admin/adverts", returnOnly);
  }

  adminDeals(days: number, returnOnly: boolean = false) {
    return this.commonReturn("/admin/deals/" + days, returnOnly);
  }

  commsTemplates(tab = 1, returnOnly: boolean = false) {
    return this.commonReturn("/admin/comms/" + tab, returnOnly);
  }

  adminAuctioneerView(saleId: string, returnOnly: boolean = false) {
    return this.commonReturn("/admin/admin-sales/" + saleId + "/auctioneer", returnOnly);
  }

  adminCustomerEdit(customerId: string, returnOnly: boolean = false) {
    return this.commonReturn("/admin/customer/" + customerId, returnOnly);
  }

  adminCustomerView(customerId: string, returnOnly: boolean = false, event?: any) {
    if (event != null) {
      event.stopPropagation();
    }
    return this.commonReturn("/admin/customer/" + customerId, returnOnly);
  }

  adminSaleProfiles(returnOnly: boolean = false) {
    return this.commonReturn("/admin/admin-sales/profiles", returnOnly);
  }

  adminSales(returnOnly: boolean = false) {
    return this.commonReturn("/admin/admin-sales", returnOnly);
  }

  adminSaleCreate(returnOnly: boolean = false) {
    return this.commonReturn("/admin/admin-sales/create", returnOnly);
  }

  adminSaleEdit(saleId: string, returnOnly: boolean = false) {
    return this.commonReturn("/admin/admin-sales/" + saleId + "/edit", returnOnly);
  }

  adminSaleView(saleId: string, returnOnly: boolean = false) {
    return this.commonReturn("/admin/admin-sales/" + saleId, returnOnly);
  }

  adminUnreconciled(returnOnly: boolean = false) {
    return this.commonReturn("/admin/accounts/unreconciled", returnOnly);
  }

  adminInvoices(returnOnly: boolean = false) {
    return this.commonReturn("/admin/accounts/invoices", returnOnly);
  }


  adminVehiclesChecker(returnOnly: boolean = false) {
    return this.commonReturn("/admin/vehicles/checker", returnOnly);
  }

  adminSaleAttendees(returnOnly: boolean = false) {
    return this.commonReturn("/admin/admin-sales/attendees", returnOnly);
  }

  adminAuctions(returnOnly: boolean = false) {
    return this.commonReturn("/admin/manage-auctions", returnOnly);
  }

  adminValuations(returnOnly: boolean = false) {
    return this.commonReturn("/admin/valuations", returnOnly);
  }

  adminVehiclesHistory(returnOnly: boolean = false) {
    return this.commonReturn("/admin/vehicles/history", returnOnly);
  }

  adminVehicleHistory(vehicleId: string, returnOnly: boolean = false) {
    return this.commonReturn("/admin/vehicles/history/" + vehicleId, returnOnly);
  }

  adminMaintAttrib(returnOnly: boolean = false) {
    return this.commonReturn("/admin/maintenance/attrib", returnOnly);
  }

  adminMaintAttribVal(id: number, returnOnly: boolean) {
    const path = "/admin/maintenance/attribval/" + id;
    return this.commonReturn(path, returnOnly);
  }

  adminMaintSite(returnOnly: boolean = false) {
    return this.commonReturn("/admin/maintenance/site", returnOnly);
  }

  adminMaintCountryProduct(returnOnly: boolean = false) {
    return this.commonReturn("/admin/maintenance/country-product", returnOnly);
  }

  adminMaintPlatform(returnOnly: boolean = false) {
    return this.commonReturn("/admin/maintenance/platform", returnOnly);
  }

  adminMaintTax(returnOnly: boolean = false) {
    return this.commonReturn("/admin/maintenance/tax", returnOnly);
  }

  adminMaintProduct(returnOnly: boolean = false) {
    return this.commonReturn("/admin/maintenance/product", returnOnly);
  }

  adminMaintCountry(returnOnly: boolean = false) {
    return this.commonReturn("/admin/maintenance/country", returnOnly);
  }

  adminMaintFaultCheck(returnOnly: boolean = false) {
    return this.commonReturn("/admin/maintenance/fault-check", returnOnly);
  }

  adminScanDashboard(returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-dashboard", returnOnly);
  }

  adminScanErrorSummary(returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-error-summary", returnOnly);
  }

  adminScanErrorDetail(scanStyleId: number, returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-error-detail/" + scanStyleId, returnOnly);
  }

  adminAlts(returnOnly: boolean = false) {
    return this.commonReturn("/admin/maintenance/alt/mmd", returnOnly);
  }

  adminScanConfigure(returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-configure", returnOnly);
  }

  adminScanField(returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-field", returnOnly);
  }

  adminScanService(returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-service", returnOnly);
  }

  adminScanStyle(returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-style", returnOnly);
  }

  adminScanCustomer(returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-customer", returnOnly);
  }

  adminScanVehicles(returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-vehicles", returnOnly);
  }

  adminScanQueue(returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-queue", returnOnly);
  }

  adminScanCustomerWithId(id: number, returnOnly: boolean = false) {
    return this.commonReturn("/admin/scan/scan-customer/" + id, returnOnly);
  }

  adminManageNegotiations(advertId: string, returnOnly: boolean = false) {
    return this.commonReturn(`/admin/manage-negotiations/${advertId}`, returnOnly);
  }

  siteAdmins(returnOnly: boolean = false) {
    return this.commonReturn(`/admin/site-admin-search`, returnOnly);
  }

  importData(vendor: string, returnOnly: boolean = false) {
    return this.commonReturn(`/admin/import-data/${vendor}`, returnOnly);
  }

  scanConfigure(returnOnly: boolean = false, scanServiceId?: number, scanStyleId?: number, scanStageId?: number, scanCustomerId?: number) {

    let appendString = "";
    let append: any[] = [];

    if (scanServiceId != null) {
      append.push(scanServiceId);

      if (scanStyleId != null) {
        append.push(scanStyleId);

        if (scanStageId != null) {

          append.push(scanStageId);

          if (scanCustomerId != null) {
            append.push(scanCustomerId);
          }
        }
      }
    }

    if (append.length > 0) {
      appendString = "/" + append.join("/");
    }

    return this.commonReturn("/admin/scan/scan-configure" + appendString, returnOnly);
  }

  commonReturn(path, returnOnly) {
    if (returnOnly) {
      return path;
    } else {
      this.urlService.redirect(path);
      return true;
    }
  }
}
